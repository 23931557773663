<template>
  <div>
    <el-dialog :title="text+'考试类型'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="23">
            <el-form-item label="类型名称:">
              <el-input placeholder="请输入"
                        style="width:100%"
                        v-model="form.type_name" />
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="排序:">
              <el-input placeholder="请输入"
                        style="width:100%"
                        v-model.number="form.sort" />
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="状态:">
              <el-switch v-model="form.status"
                         :active-value="1"
                         :inactive-value="0"
                         active-text="开启"
                         inactive-text="禁用" />
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveApi } from '@/api/examType.js'
export default {
  data () {
    return {
      dialogVisible: false,
      text: '',
      form: {}
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    async save () {
      let form = JSON.parse(JSON.stringify(this.form))
      await saveApi(form)
      this.$notify({
        title: '提示',
        message: '成功!',
        type: 'success'
      });
      this.handleClose()
      this.$parent.search()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>