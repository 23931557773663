import { instance } from '@/utils/http.js'

// 获取考试类型列表
export const getList = function (params) {
  return instance({
    url: '/api/v1/test/type_list',
    method: 'get',
    params
  })
}

// 新增-修改考试类型
export const saveApi = function (data) {
  return instance({
    url: '/api/v1/test/type_create',
    method: 'post',
    data
  })
}